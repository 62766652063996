// Library
import React, {useState} from 'react';
import _ from 'lodash';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {Container, Navbar, Nav, NavDropdown, Modal} from 'react-bootstrap';
// Buttons
import CartButton from '../../Components/CartButton';
// Logo
import Logo from '../../Components/Logo';
// Colors
import Colors from "../../Config/Colors";

// Redux Action
import {logout} from "../../Store/User/actions";
import {_setOpen, _clearCart, _setChildOpen, saveCartStorage} from "../../Store/Cart/action";
// Cart
import Cart from '../../Pages/CartPage';
// Cart Modal
import CartModal from "../CartModal";
// Linker
import Linker from "../../Components/Link";
// Action Link
import ActionLink from "../../Components/ActionLink";
import Logger from "../../Utils/Logger";
import {toaster} from "../../Utils/Helpers";
// Components
import InputBox from "../../Components/Input";
import Button from "../../Components/Button";
import ThemeSwitcher from "../../Components/ThemeSwitcher";
import CustomDialog from "../../Components/Dialogs";


const Header = (props) => {
    const {logout} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const cartState = useSelector(state => state.cart);
    const userState = useSelector(state => state.user);
    const {themeMode} = useSelector(state => state.theme);

    const [cartTitle, setCartTitle] = useState("");
    const [openCartTitle, setOpenCartTitle] = useState(false);

    const cartTitleToggle = () => {
        if (openCartTitle) {
            setOpenCartTitle(false);
        } else {
            setOpenCartTitle(true);
        }
    }

    const cartToggle = () => {
        if (cartState.open) {
            dispatch(_setOpen(false));
        } else {
            dispatch(_setOpen(true));
        }
    }

    const cartChildToggle = () => {
        if (cartState.openChild) {
            dispatch(_setChildOpen(false));
        } else {
            dispatch(_setChildOpen(true));
        }
    }

    const clearCart = () => {
        dispatch(_clearCart(() => {
            cartToggle();
        }));

    }


    const saveCart = (tempCart = false) => {
        Logger.log("CART IS SAVING: ", cartState.cartDetailRevamped);

        if (cartTitle) {

            if (cartState.cartDetailRevamped &&
                cartState.selectedCustomer && cartState.selectedStore) {
                const cart = {
                    cart: cartState.cartDetailRevamped,
                    customer: cartState.selectedCustomer,
                    store: cartState.selectedStore
                }

                const data = {
                    cart: JSON.stringify(cart),
                    userId: userState.userData.id,
                    title: cartTitle,
                    tempCart: tempCart
                }

                dispatch(saveCartStorage(data, (err) => {
                    if (!err) {
                        toaster("Cart Saved Successfully", "success");
                        setOpenCartTitle(false);
                    }
                }));
            } else {
                toaster("Something Went Wrong Saving the cart", "danger");
            }
        } else {
            toaster("Enter Cart Title", "danger");
        }

    }

    // Logger.log("THEME IN HEADER: ", themeMode);
    const bgColor = themeMode === 'light' ? Colors.whiteName : Colors.blackName;
    const textColor = themeMode === 'light' ? Colors.blackName : Colors.whiteName;
    document.body.style.setProperty('background-color', bgColor);
    document.documentElement.style.setProperty('background-color', bgColor)

    return (
        <>
            <Navbar bg={bgColor} variant={themeMode} expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#home">
                        <div style={{width: "60%", float: "left"}} className={"left"}>
                            <Logo/>
                        </div>
                        <div   style={{
                            lineHeight: "60px",
                            height: "20px",
                            textIndent: "5px",
                            fontSize: "15px",
                        }}>
                            v{window.config.VERSION}
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Linker to={"/home"}>
                                <Nav.Link as={"div"} style={{color:textColor}} >Home</Nav.Link>
                            </Linker>
                            {userState.isAdmin ? (
                                <NavDropdown title={<span style={{color:textColor}}>Dashboard</span>}
                                             id="basic-nav-dropdown">
                                    <NavDropdown.Item as={"div"}>
                                        <Linker to={"/order-reports"}>
                                            <ActionLink clicker={() => Logger.log("Nothing")}>Monthly</ActionLink>
                                        </Linker>

                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={"div"}>
                                        <Linker to={"/order-reports-by-dates-and-reps"}>
                                            <ActionLink clicker={() => Logger.log("Nothing")}>Date & Rep
                                                Filters</ActionLink>
                                        </Linker>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : null}
                            <NavDropdown title={<span style={{color:textColor}}>Sales Order</span>}
                                         id="basic-nav-dropdown">
                                <NavDropdown.Item as={"div"}>
                                    <Linker to={"/order"}>
                                        <ActionLink clicker={() => Logger.log("Nothing")}>By Season &
                                            Divisions</ActionLink>
                                    </Linker>

                                </NavDropdown.Item>
                                <NavDropdown.Item as={"div"}>
                                    <Linker to={"/order/style"} >
                                        <ActionLink clicker={() => Logger.log("Nothing")}>By Style Number</ActionLink>
                                    </Linker>
                                </NavDropdown.Item>

                                <NavDropdown.Item as={"div"}>
                                    <Linker to={"/all-orders"} >
                                        <ActionLink clicker={() => Logger.log("Nothing")}>All Orders</ActionLink>
                                    </Linker>
                                </NavDropdown.Item>

                                <NavDropdown.Item as={"div"}>
                                    <Linker to={"/cart-storage"} >
                                        <ActionLink clicker={() => Logger.log("Nothing")}>Cart Storage</ActionLink>
                                    </Linker>
                                </NavDropdown.Item>
                            </NavDropdown>

                            {userState.isAdmin ? (

                                <NavDropdown title={<span style={{color:textColor}}>User Management</span>}
                                             id="basic-nav-dropdown">
                                    <NavDropdown.Item as={"div"}>
                                        <Linker to={"/user-management/manage"}>
                                            <ActionLink clicker={() => Logger.log("Nothing")}>Manage</ActionLink>
                                        </Linker>

                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={"div"}>
                                        <Linker to={"/user-management/add"}>
                                            <ActionLink clicker={() => Logger.log("Nothing")}>Add</ActionLink>
                                        </Linker>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : null}

                            <NavDropdown title={<span style={{color:textColor}}>Reports</span>}
                                         id="basic-nav-dropdown">

                                <NavDropdown.Item as={"div"}>
                                    <Linker to={`/time-phased-report`}>
                                        <ActionLink clicker={() => Logger.log("Nothing")}>Time Phased Report</ActionLink>
                                    </Linker>

                                </NavDropdown.Item>

                                {window.config.ACTIVE_SEASONS_FOR_REPORTS.length ? _.map(window.config.ACTIVE_SEASONS_FOR_REPORTS, (season, index) => (
                                    <NavDropdown.Item as={"div"} key={index}>
                                        <Linker to={`/report/${season}`}>
                                            <ActionLink clicker={() => Logger.log("Nothing")}>{season} - Inventory
                                                (PDF)</ActionLink>
                                        </Linker>

                                    </NavDropdown.Item>
                                )) : null}
                                {window.config.ACTIVE_SEASONS_FOR_REPORTS_EXCEL.length ? _.map(window.config.ACTIVE_SEASONS_FOR_REPORTS_EXCEL, (season, index) => (
                                    <NavDropdown.Item as={"div"} key={index}>
                                        <a href={`${window.config.REPORT_EXCEL_LINK + season}.xlsx`}>
                                            <ActionLink clicker={() => Logger.log("Nothing")}>{season} - UPC List
                                                (Excel)</ActionLink>
                                        </a>

                                    </NavDropdown.Item>
                                )) : null}
                            </NavDropdown>


                            <NavDropdown title={<span style={{color:textColor}}>Support</span>}
                                         id="basic-nav-dropdown">
                                <NavDropdown.Item as={"div"}>
                                    <Linker to={"/support"}>
                                        <ActionLink clicker={() => Logger.log("Nothing")}>Send Support Email</ActionLink>
                                    </Linker>

                                </NavDropdown.Item>
                                <NavDropdown.Item as={"div"}>
                                    <Linker to={"/credit-app"} >
                                        <ActionLink clicker={() => Logger.log("Nothing")}>Credit App</ActionLink>
                                    </Linker>
                                </NavDropdown.Item>

                                <NavDropdown.Item as={"div"}>
                                    <Linker to={"/embroidery-placement"} >
                                        <ActionLink clicker={() => Logger.log("Nothing")}>Embroidery Placement</ActionLink>
                                    </Linker>
                                </NavDropdown.Item>

                            </NavDropdown>



                        </Nav>
                        <Navbar.Collapse className="justify-content-end">
                            {window.config.THEME_CHANGER ? (
                                <ThemeSwitcher/>
                            ) : null}
                            <NavDropdown title={<span className={themeMode === 'light' ? 'text-dark' : 'text-light'}>Hello,  <b>{userState.userData.name} </b> </span>}
                                         id="basic-nav-dropdown">
                                <NavDropdown.Item as={"div"}>
                                    <Linker to={`/change-password`}>
                                        Change Password
                                    </Linker>
                                </NavDropdown.Item>
                                <NavDropdown.Item as={"div"}>
                                    <ActionLink clicker={logout}>Logout</ActionLink>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <CartButton clicker={cartToggle} cartCount={cartState.cartDetailRevamped.length}/>
                        </Navbar.Collapse>
                    </Navbar.Collapse>
                </Container>
                <CartModal
                    title={"Cart"}
                    body={<Cart/>}
                    cartState={cartState}
                    open={cartState.open}
                    openChild={cartState.openChild}
                    cartChildToggle={cartChildToggle}
                    toggler={cartToggle}
                    clearCart={clearCart}
                    history={history}
                    saveCart={() => setOpenCartTitle(true)}
                />

                <CustomDialog
                    open={openCartTitle}
                    toggler={cartTitleToggle}
                    title={"Set Cart Title"}
                >
                    <InputBox
                        margin={'dense'}
                        fullWidth
                        id="title"
                        name="title"
                        label="Add Title For Cart"
                        variant="standard"
                        value={cartTitle}
                        changer={(e) => setCartTitle(e.target.value)}
                    />
                    <Button color="error" clicker={() => setOpenCartTitle(false)}>
                        Close
                    </Button>
                    {"    "}
                    <Button color="primary" clicker={saveCart}>
                        Save
                    </Button>
                    {"    "}
                    <Button color="secondary" clicker={() => {
                        saveCart(true);
                    }}>
                        Save Temp
                    </Button>

                </CustomDialog>

                <Modal show={false} onHide={cartTitleToggle}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set Cart Title</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputBox
                            margin={'dense'}
                            fullWidth
                            id="title"
                            name="title"
                            label="Add Title For Cart"
                            variant="standard"
                            value={cartTitle}
                            changer={(e) => setCartTitle(e.target.value)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="error" clicker={() => setOpenCartTitle(false)}>
                            Close
                        </Button>
                        {"    "}
                        <Button color="primary" clicker={saveCart}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Navbar>

        </>
    )
}
export default connect(null, {logout, _setOpen, _clearCart})(Header);
