// Library
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Row, Table} from 'react-bootstrap';
import _ from 'lodash';
// Container
import PageContainer from "../../Containers/PageContainer";
import TimePhasedReportViewer from "../../Containers/TimePhasedReportViewer";
// Logger
import Logger from "../../Utils/Logger";
import Input from "../../Components/InputV2";
import {toaster} from "../../Utils/Helpers";
import {Col} from "reactstrap";
// Redux Actions
import {getTimePhasedReportByStyle} from "../../Store/OrderReports/action";
import Loader from "../../Components/Loader";


const TimePhasedReportPage = ({data}) => {

    const [styleNumber, setStyleNumber] = useState("");

    const orderReportState = useSelector(state => state.orderReports);
    const {themeMode} = useSelector(state => state.theme);

    const dispatch = useDispatch();

    const searchItem = () => {
        // Logger.log("BTN CLICKER");

        if (styleNumber && styleNumber.length) {
            dispatch(getTimePhasedReportByStyle(styleNumber, (err) => {
                if (!err) {

                }
            }));
        } else {
            toaster("Please enter the size");
        }
    }

    return (
        <PageContainer>
            <Row>
                <Col sm={{size: "6", offset: 3, style: {textAlign: "center"}}}>
                    <Input
                        labelSize={2}
                        label={"Style Number"}
                        button={true}
                        btnLabel={"Search"}
                        btnClicker={searchItem}
                        inputSize={7}
                        changer={(e) => setStyleNumber(e.target.value)}
                        value={styleNumber}

                    />
                </Col>
            </Row>
            <Row>
                <Col sm={{size: "12"}}>
                    <Row>
                        {orderReportState.processing ? (
                            <Col sm={12} className={"text-center mt-5 pb-5"}><Loader/></Col>
                        ) : (
                            <>
                                {orderReportState.timePhasedReport ? (
                                    <TimePhasedReportViewer data={orderReportState.timePhasedReport}/>
                                ) : null}
                            </>
                        )}
                    </Row>
                </Col>
            </Row>
        </PageContainer>
    );
};

export default TimePhasedReportPage;
