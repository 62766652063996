// Libraries
import _ from 'lodash';
// Logger
import Logger from '../../Utils/Logger';
// Order Parser
import {extractSizesFromAllItemsList} from "../Order/parser";


const COST_COLOR = "rgb(38,243,31)";
const COST_COLOR_BORDER = "rgba(38,243,31, 0.5)";

const DELIVERY_COLOR = "rgb(255, 99, 132)";
const DELIVERY_COLOR_BORDER = "rgba(255, 99, 132, 0.5)";

const ORDER_COLOR = "rgb(53, 162, 235)";
const ORDER_COLOR_BORDER = "rgba(53, 162, 235, 0.5)";


export const getParsedReports = (reportsData, monthsCount) => {

    const dataSetToReturn = [];
    const dataSetToReturnForCost = [];

    const lastSixMonths = [];
    const monthNameArr = [];

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const today = new Date();
    let d;
    let month;

    for (let i = monthsCount; i >= 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = monthNames[d.getMonth()];
        Logger.log(month);
        const finder = _.find(reportsData, p => p.monthName === month.toUpperCase());
        if (finder) {
            lastSixMonths.push(finder);
            monthNameArr.push(finder.monthName);
        }
    }
    dataSetToReturnForCost.push(
        {
            label: "Revenue",
            data: _.map(lastSixMonths, p => p.totalSum),
            borderColor: COST_COLOR,
            backgroundColor: COST_COLOR_BORDER,
        }
    );
    dataSetToReturn.push(
        {
            label: "Deliveries",
            data: _.map(lastSixMonths, p => p.totalDeliveries),
            borderColor: DELIVERY_COLOR,
            backgroundColor: DELIVERY_COLOR_BORDER,
        }
    );
    dataSetToReturn.push(
        {
            label: "Transactions",
            data: _.map(lastSixMonths, p => p.totalOrders),
            borderColor: ORDER_COLOR,
            backgroundColor: ORDER_COLOR_BORDER,
        }
    );

    return {
        revenue: {
            labels: monthNameArr,
            datasets: dataSetToReturnForCost
        },
        order: {
            labels: monthNameArr,
            datasets: dataSetToReturn
        }
    }
}

export const getParsedFilterReports = (reportsData, requestData, userData) => {

    const parsedDataSet = [];

    const dataSet = [];
    const dataSetForRevenue = [];

    Logger.log("REPORTS DATA: ", reportsData);
    Logger.log("REQUESTED DATA: ", requestData);
    Logger.log("USER DATA: ", userData);

    const REP_LABELS = [];
    Logger.log("ALL REPS: ", userData);

    _.map(reportsData, (data, index) => {

        const userFinder = _.find(userData, p => p.id === data.userId);

        Logger.log("USERFINDER: ", userFinder);
        REP_LABELS.push(userFinder.name);
        parsedDataSet.push(data.report);
    });

    Logger.log("PARSED REPORTS: ", parsedDataSet);

    dataSet.push(
        {
            label: "Transactions",
            data: _.map(parsedDataSet, p => p.totalOrders),
            borderColor: ORDER_COLOR,
            backgroundColor: ORDER_COLOR_BORDER,
        }
    );
    dataSet.push(
        {
            label: "Deliveries",
            data: _.map(parsedDataSet, p => p.totalDeliveries),
            borderColor: DELIVERY_COLOR,
            backgroundColor: DELIVERY_COLOR_BORDER,
        }
    );

    dataSetForRevenue.push(
        {
            label: "Revenue",
            data: _.map(parsedDataSet, p => p.totalSum),
            borderColor: COST_COLOR,
            backgroundColor: COST_COLOR_BORDER,
        }
    );

    return {
        revenue: {
            labels: REP_LABELS,
            datasets: dataSetForRevenue
        },
        order: {
            labels: REP_LABELS,
            datasets: dataSet
        }
    }
}

export const extractTimePhasedReport = (productData, reportsData) => {

    const data = [];
    _.forEach(reportsData , (reportData, index) => {
        data.push(parseTimePhasedReport(productData, reportData));
    });

    return data;

}

export const parseTimePhasedReport = (productData, reportsData) => {
    Logger.log("==== In Parser Time Phased Report ====");
    Logger.log("==== Report Data : ", reportsData);
    Logger.log("==== Product Data : ", productData);

    const sizeData = extractSizesFromAllItemsList(productData);

    Logger.log("==== Size Data : ", sizeData);

    const pastReport = reportsData.past;
    const futureReport = reportsData.future;
    const rangedReport = reportsData.ranges;
    const recordReport = reportsData.records[0];
    const styleName = reportsData.style;

    const dataToBeReturned = [];
    const sizeParsed = [];

    _.map(sizeData, (data, index) => {
        Logger.log("==== Size Data : ", data);
        const sizeNumber = _.toLower(data.sizeNumber) + "_available";
        Logger.log("==== Size Number to Small : ", sizeNumber);

        sizeParsed.push({
            ...data,
            sizeNumberParsed: sizeNumber,
        });

    });

    Logger.log("==== Size Parsed : ", sizeParsed);

    const passedDataParsed = getSingleRangeData(pastReport, sizeParsed, "", "PAST");
    const futureDataParsed = getSingleRangeData(futureReport, sizeParsed, "", "FUTURE");

    Logger.log("==== PAST Data Parsed : ", passedDataParsed);
    Logger.log("==== FUTURE Data Parsed : ", futureDataParsed);

    dataToBeReturned.push(passedDataParsed);

    _.map(rangedReport, (data, index) => {
        const startDate = data.start;
        const [startYear, startMonth, startDay] = startDate.split("T")[0].split("-");

        const endDate = data.end;
        const [endYear, endMonth, endDay] = endDate.split("T")[0].split("-");

        dataToBeReturned.push(getSingleRangeData(
                data,
                sizeParsed,
                `${startMonth}/${startDay}/${startYear}`,
                `${endMonth}/${endDay}/${endYear}`,
                recordReport
            )
        );
    });

    dataToBeReturned.push(futureDataParsed);
    Logger.log("==== Data To be Returned : ", dataToBeReturned);

    return  {
        style: styleName,
        data: dataToBeReturned
    }
}


const getSingleRangeData = (reportData, sizeData, labelOne, labelTwo, recordData = null) => {
    const singleRangeData = {};
    singleRangeData.labelOne = labelOne;
    singleRangeData.labelTwo = labelTwo;
    singleRangeData.sizes = [];
    singleRangeData.original = reportData;
    singleRangeData.styleName = reportData.style;

    _.forOwn(reportData, (value, index) => {
        // Logger.log("==== Index : ", index);
        // Logger.log("==== Value : ", value);

        if (index === "totalorders") {
            singleRangeData.totalOrders = value;
        }

        if (index === "totalavailable") {
            singleRangeData.totalAvailable = value;
        }
        if (index === "totalwip") {
            singleRangeData.totalWIP = value;
        }

        const sizeFinder = _.find(sizeData, p => p.sizeNumberParsed === index);

        if (sizeFinder) {

            const sizeDataPushing = {
                size: sizeFinder.sizeName,
                qty: value,
            };
            if (recordData) {
                sizeDataPushing.onHand = recordData[`ONHAND_${sizeFinder.sizeNumber}`];
                singleRangeData.recordReport = recordData;
            }

            singleRangeData.sizes.push(sizeDataPushing);

        }
    });

    return singleRangeData;
}

