// Action Types
import * as Actions from "./action-types";
// Logger
import Logger from '../../Utils/Logger';
// Axios
import axios from '../../Utils/AxiosWrapper';
// API URLS
import * as URLS from '../../Utils/ApiUrls';
// Error Handler
import errorHandler from "../../Utils/errorHandler";
// Parser
import {getParsedReports, getParsedFilterReports, parseTimePhasedReport, extractTimePhasedReport} from './parser';

export const getLastYearReports = (monthsCount, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));

        axios.get(URLS.OrderReportsUrl.GetLastYearReports)
            .then(response => {
                Logger.log("ORDER REPORTS RESPONSE: ", response.data);

                const {content} = response.data;

                dispatch(_setReports(content));
                dispatch(_setReportsParsed(getParsedReports(content, monthsCount)));

                dispatch(_setProcessing(false));
                dispatch(_setError(false));


                cb && cb();
            })
            .catch(error => {
                Logger.error("ORDER REPORTS: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}

export const getLastYearReportsByUserId = (data,  cb) => {
    return (dispatch, getState) => {
        dispatch(_setProcessing(true));

        const userState = getState().user;
        Logger.log("USER STATE:  ", userState.allUsersForAdding.reps);

        axios.post(URLS.OrderReportsUrl.GetLastYearReportsByUsers, data)
            .then(response => {
                Logger.log("ORDER REPORTS RESPONSE: ", response.data);

                const {content} = response.data;

                dispatch(_setReports(content));
                dispatch(_setUserReportsParsed(getParsedFilterReports(content, data, userState.allUsersForAdding.reps)));

                dispatch(_setProcessing(false));
                dispatch(_setError(false));


                cb && cb();
            })
            .catch(error => {
                Logger.error("ORDER REPORTS: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}

export const getTimePhasedReportByStyle = (style, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        const productFetcher = axios.get(URLS.ProductsUrls.GetItemBySizeNumber + style);
        const availabilityFetcher = axios.getWithAES(URLS.OrderReportsUrl.GetTimePhasedReportByStyle(style, formattedDate));


        Promise.all([productFetcher, availabilityFetcher])
            .then(promiseResponse => {
                Logger.log("ORDER REPORTS RESPONSE: ", promiseResponse);

                const productResponse = promiseResponse[0].data.content;
                const statusResponse = promiseResponse[1].data.response.data.timephasedreport;

                dispatch(_setTimePhasedReport(extractTimePhasedReport(productResponse, statusResponse)));

                dispatch(_setProcessing(false));
                dispatch(_setError(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("ORDER REPORTS: ", error);
                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            })
    }
}

export const setCustomMonthsForFetchedReports = (reports, monthsCount, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));
        dispatch(_setReportsParsed(getParsedReports(reports, monthsCount)));
        dispatch(_setProcessing(false));

        cb && cb();
    }
}


// Dispatch Actions
const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};
const _setError = (payload) => {
    return {
        type: Actions.SET_ERROR,
        payload,
    };
};
// const _setMessage = (payload) => {
//     return {
//         type: Actions.SET_MESSAGE,
//         payload,
//     };
// };
const _setReports = (payload) => {
    return {
        type: Actions.SET_REPORTS,
        payload,
    };
};
const _setReportsParsed = (payload) => {
    return {
        type: Actions.SET_REPORTS_PARSED,
        payload,
    };
};
const _setUserReportsParsed = (payload) => {
    return {
        type: Actions.SET_USER_REPORTS_PARSED,
        payload,
    };
};

const _setTimePhasedReport = (payload) => {
    return {
        type: Actions.SET_TIME_PHASED_REPORT,
        payload,
    };
};
