import React from 'react';
import {Row, Table} from 'react-bootstrap';
import {Col} from 'reactstrap';
import './style.css';
import _ from 'lodash';
import Logger from '../../Utils/Logger';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const TimePhasedReportViewer = (props) => {

    const {data} = props;

    const renderReport = (data) => {


        if (data) {
            return _.map(data, (report, index) => {
                return (
                    (
                        <div className={`col mt-2 row-number-${index}`} key={index}>
                            <div className="mt-2">
                                <input type="text" className="form-control text-align-center" value={report.labelOne}
                                       readOnly/>
                            </div>
                            <div className="mt-1">
                                <input type="text" className="form-control text-align-right" value={report.labelTwo}
                                       readOnly/>
                            </div>
                            <div className="mt-1">
                                <input type="text" className="form-control text-align-right" value={report.totalWIP}
                                       readOnly/>
                            </div>
                            <div className="mt-1">
                                <input type="text" className="form-control text-align-right" value={report.totalOrders}
                                       readOnly/>
                            </div>
                            <div className="mt-1 mb-5">
                                <input type="text" className={`form-control text-align-right ${
                                    report.totalAvailable > 0 ? "background-green" : "background-red"
                                }`} value={report.totalAvailable} readOnly/>
                            </div>

                            {_.map(report.sizes, (sizes, sizeIndex) => (
                                <>

                                    <div className={`col mt-2 row-number-${sizeIndex}`}>
                                        <input type="text" className="form-control text-align-right" value={sizes.qty}
                                               readOnly/>
                                    </div>
                                </>
                            ))}
                        </div>
                    )
                )
            })
        }
    }


    const renderOnHand = (data) => {
        if (data) {
            const rangeToGet = data[1];

            return _.map(rangeToGet.sizes, (size, index) => {
                return (
                    <>
                        <div className="col mt-2">
                            <input type="text" className="form-control text-align-right" value={size.onHand} readOnly/>
                        </div>
                    </>
                )
            })
        }
    }


    const renderSizeNames = (data) => {
        if (data) {
            const sizes = data[1].sizes;
            return _.map(sizes, (size, index) => {
                return (
                    <>
                        <div className="size-div">{size.size}</div>
                    </>
                )
            })

        }
    }


    return (
        <>
            {data ? (

                <>
                    {_.map(data, (reportData, index) => (
                        <div style={{background: "white", width: "98%", margin: "1%"}}>
                            <Col sm={{size: "12"}} className={"style-title"}>
                                <strong>{reportData.style}</strong>
                            </Col>
                            <Col sm={{size: "12"}}>

                                <Row className={"mt-5"}>

                                    <div className="row">

                                        <div className="col-2 margin-top-130">
                                            <div className="col mt-4">ON HAND</div>
                                            <div className="col mt-2 mb-5">
                                                <input type="text"
                                                       className="form-control text-align-right background-green"
                                                       value="100" readOnly/>
                                            </div>
                                            {renderOnHand(reportData.data)}
                                        </div>

                                        <div className="col-1 pr-0">
                                            <div className="col-12 pl-0 pr-0 mb-5">
                                                <div className="margin-top-110">WIP</div>
                                                <div className="margin-top-20">OPEN ORDERS</div>
                                                <div className="margin-top-20 mb-5">AVAILABLE</div>
                                                {renderSizeNames(reportData.data)}
                                            </div>
                                        </div>

                                        {renderReport(reportData.data)}

                                    </div>
                                </Row>

                            </Col>
                        </div>
                    ))}
                </>

            ) : null}
        </>
    )
}
export default TimePhasedReportViewer;
